import React from 'react'
import { Link as GatsbyLink, graphql } from 'gatsby'
import LayoutPublic from "../components/layouts/layoutPublic"
// import ReactMarkdown from 'react-markdown'


import Seo from '../components/Seo/';
import '../components/ad/annoncesWysiwyg.scss'

import {
    Box,
    Heading,
    Stack,
    Text,
    Link,
    List,
    ListItem,

    Grid,
    Flex
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { BiLeftArrowAlt } from 'react-icons/bi';
import { wrapperPadding } from '../utils/wrapperPadding';

var markdown = require("markdown").markdown;
const siteConfig = require('../../config')

const accountHubTemplate = (props) => {

    // console.log( props )

    const title = {
        "fr": "À propos de Wanaboat, ce que vous voulez savoir"
        ,
        "en": "About iWannaboat, all you want to know"
    }
    const description = {
        "fr": "Quelques mots sur l'histoire de Wanaboat, ses valeurs et ses convictions. À propos et contact."
        ,
        "en": "A few words on the history of iWannaboat, its values and beliefs. About and contact."
    };

    return (
        <LayoutPublic lang={process.env.GATSBY_LANG}>
            <Seo
                title={title[process.env.GATSBY_LANG]}
                description={description[process.env.GATSBY_LANG]}
                slug={siteConfig.links.about[process.env.GATSBY_LANG]}
            />

            <Stack
                spacing={8}
                pb={8}
                pt={{ base: 8, lg: 0 }}
            >
                <Flex
                    {...wrapperPadding()}
                >
                    <Flex
                        as={GatsbyLink}
                        to='../'
                        alignItems={'center'}
                        color='white'
                        bgColor={'primary'}
                        borderRadius='full'
                        px={3}
                        py={1}
                    >
                        <Box
                            mr={1}
                        >
                            <BiLeftArrowAlt />
                        </Box>
                        <FormattedMessage id='main.back' />
                    </Flex>
                </Flex>
                <Heading
                    {...wrapperPadding()}
                    fontSize="32px"
                    fontWeight="700"
                >
                    <Text as="span" color="blue.brand" >
                        <FormattedMessage id="about.hi.captain" />{' '}
                    </Text>
                    <FormattedMessage id="about.what.do.you.want" />
                </Heading>


                <List
                    {...wrapperPadding()}
                    spacing={0}
                    display={'grid'}
                    gridTemplateColumns={{ base: '100%', lg: 'repeat(2, 420px)' }}
                >
                    <ListItem>
                        <Link
                            as={GatsbyLink}
                            to={siteConfig.links.publish[process.env.GATSBY_LANG]}
                            fontWeight="normal"
                            fontSize="20px"
                            color="blue.brand"
                            variant="link"
                            size='sm'
                        >
                            → <FormattedMessage id="about.publish.an.ad" />
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link
                            as={GatsbyLink}
                            to={siteConfig.links.account[process.env.GATSBY_LANG]}
                            fontWeight="normal"
                            fontSize="20px"
                            color="blue.brand"
                            variant="link"
                            size='sm'
                        >
                            → <FormattedMessage id="about.access.to.your.account" />
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link
                            as='a'
                            href="#about"
                            fontWeight="normal"
                            fontSize="20px"
                            color="blue.brand"
                            variant="link"
                            size='sm'
                        >
                            → <FormattedMessage id="about.get.more.infos" />
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link
                            as='a'
                            href="#pantheon"
                            fontWeight="normal"
                            fontSize="20px"
                            color="blue.brand"
                            variant="link"
                            size='sm'
                        >
                            → <FormattedMessage id="about.alloffame.title" />
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link
                            as='a'
                            href="#contact"
                            fontWeight="normal"
                            fontSize="20px"
                            color="blue.brand"
                            variant="link"
                            size='sm'
                        >
                            → <FormattedMessage id="about.contact.us" />
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link
                            as={GatsbyLink}
                            to={siteConfig.links.donation[process.env.GATSBY_LANG]}
                            fontWeight="normal"
                            fontSize="20px"
                            color="blue.brand"
                            variant="link"
                            size='sm'
                        >
                            → <FormattedMessage id="about.support" /> 👍
                        </Link>
                    </ListItem>
                    <ListItem
                        display={'none'}

                    >
                        <Link
                            as={GatsbyLink}
                            to={siteConfig.links.sellersHub[process.env.GATSBY_LANG]}
                            fontWeight="normal"
                            fontSize="20px"
                            color="blue.brand"
                            variant="link"
                            size='sm'
                        >
                            → <FormattedMessage id="about.go.to.pro.list" />
                        </Link>
                    </ListItem>
                    {/* <ListItem>
                            <Button
                                as={GatsbyLink}
                                to={siteConfig.links.blog[process.env.GATSBY_LANG]}
                                fontWeight="normal"
                                fontSize="20px"
                                color="blue.brand"
                                variant="link"
                                size='sm'
                            >
                                → <FormattedMessage id="about.read.our.blog" />
                            </Button>
                        </ListItem> */}
                </List>



                <Grid
                    templateColumns={{ base: '100%', xl: '60% 1fr' }}
                    gap={{ lg: 10 }}
                >
                    <Stack
                        spacing={6}

                    >
                        <Box
                            id="about"
                            fontSize='18px'
                            fontWeight='400'
                            backgroundColor="white"
                            p={{ base: '1rem', lg: '2rem' }}
                            // mx={{ base: '-1rem', lg: '-2rem' }}
                            maxW='800px'
                            borderRadius='0 0 10px 10px'
                        >
                            <Box
                                className="wysiwyg"
                                dangerouslySetInnerHTML={{
                                    "__html": markdown.toHTML(props.data.page.childContentfulPageBodyTextNode.body)
                                }}
                            />
                            <Box
                                id="contact"
                                backgroundColor="gray.50"
                                borderTop="solid 1px"
                                borderTopColor="gray.100"
                                borderBottom="solid 1px"
                                borderBottomColor="gray.100"
                                p={{ base: '1rem', lg: '2rem' }}
                                mx={{ base: '-1rem', lg: '-2rem' }}
                                mt='2rem'
                                fontSize='16px'

                            >
                                <Heading
                                    as="h4"
                                    fontSize="18px"
                                    color='blue.900'
                                    fontWeight="500"
                                    textTransform="uppercase"
                                    letterSpacing="0.075rem"
                                    mb='.5rem'
                                >
                                    Contact :
                                </Heading>
                                <FormattedMessage id="about.page.contact.notice" values={{ email: siteConfig.emails[process.env.GATSBY_LANG] }} />
                                {/* <FormattedMessage id="about.page.we.always.reply" /> on répond toujours. */}
                            </Box>
                            <Box
                                id="pantheon"
                                backgroundColor="white"
                                borderTopColor="gray.100"
                                p={{ xd: '1rem', lg: '0rem' }}
                                mt='2rem'
                                fontSize='16px'
                            >
                                <Heading
                                    as="h4"
                                    fontSize="18px"
                                    color='blue.900'
                                    fontWeight="200"
                                    textTransform="uppercase"
                                    letterSpacing="0.075rem"
                                    mb='.5rem'
                                >
                                    <FormattedMessage id="about.alloffame.title" />
                                </Heading>
                                <Text>
                                    <FormattedMessage id="about.alloffame.notice" />
                                </Text>
                                <List my='1rem'>
                                    {props.data.page.data.usersToThank.map(item =>
                                        <Box
                                            key={item.name}
                                            textTransform="uppercase"
                                            display="inline-block"
                                            p='.1rem .2rem'
                                            letterSpacing='0.05rem'
                                            fontSize='14px'
                                            color='blue.900'
                                        >
                                            {item.name}
                                            <span> - </span>
                                        </Box>
                                    )}
                                </List>
                                {/* <FormattedMessage id="about.page.we.always.reply" /> on répond toujours. */}
                            </Box>

                        </Box>

                    </Stack>
                    <Box
                        {...wrapperPadding()}
                        mt={{ base: 8, lg: 0 }}
                    >
                        <Heading
                            color="gray.800"
                            textTransform='uppercase'
                            fontSize='13px'
                            color='blue.600'
                            letterSpacing='.05rem'
                            fontWeight="normal"
                            mb='.5rem'
                            as="h4">Derniers articles sur les évolutions de Wanaboat&nbsp;:</Heading>
                        <Stack spacing='2rem'>
                            {props.data.posts.edges.map(post =>
                                <Box
                                    key={post.node.contentful_id}
                                    background="white"
                                    p="1.5rem"
                                    borderRadius="lg"
                                    cursor='pointer'
                                    _hover={{
                                        backgroundColor: 'gray.100',
                                        color: 'blue.brand'
                                    }}
                                    as={GatsbyLink}
                                    to={`${post.node.slug}`}
                                >
                                    {post.node.title}
                                </Box>
                            )}
                        </Stack>
                    </Box>
                </Grid>
            </Stack>

        </LayoutPublic>
    )
}

export default accountHubTemplate

export const pageQuery = graphql`
  query aboutHubQuery ( $lang: String! ){
    page: contentfulPage(
        contentful_id: { eq: "26ib0xCeZQlKrqLIA8Qnho" }
        node_locale: { eq: $lang }
    ) {
        contentful_id
        title
		childContentfulPageBodyTextNode{
            body
        }
        data{
            usersToThank{ name }
        }
    }
    posts: allContentfulPost(
        filter:{ node_locale: { eq: $lang } }
    ){
        edges{
            node{
                title
                slug
                contentful_id
            }
        }
    }
}
`;